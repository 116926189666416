html {
  font-size: 100%;
}

.App {
  width: 100vw;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
}

.App section {
  flex: 100%;
  height: 100vh;
}

.App-logo {
  width: 20vw;
  max-width: 110px;
  pointer-events: none;
}

.App-header {
  margin-bottom: 10vh;
}

.App-header h1 {
  margin: 4vh 0 !important;
  font-size: 5rem !important;
  font-weight: 400 !important;
}

.App-main {
  height: 100vh;
  display: flex;
  justify-content: center;
  color: #fff;
  position: relative;
}

.App-note {
  font-size: .8rem;
  position: absolute;
  left: 2vw;
  bottom: 2vh;
}

.App-illustration {
  display: flex;
  align-items: center;
  background: #fff;
  position: relative;
}

.App-illustration .illustration {
  width: 100%;
}

.App-illustration .illustration-logo {
  position: absolute;
  right: 2vw;
  bottom: 2vh;
}

.App-content {
  display: flex;
  justify-content: center;
  background: #289ce1; /* Old browsers */
  background: -moz-linear-gradient(top,  #289ce1 0%, #006ab0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #289ce1 0%,#006ab0 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #289ce1 0%,#006ab0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#289ce1', endColorstr='#006ab0',GradientType=0 ); /* IE6-9 */
}

.App-content, .App-illustration {
  flex: 0 1 50%;
}

.App-body {
  /* width: 95vw; */
  width: 650px;
  margin-top: 5vh;
  padding: 5vw;
}

.App-body h1 {
  font-size: 2.6rem;
  font-weight: 900;
}

.App-body p {
  font-size: 1.5rem;
}

.App-link {
  color: #61dafb;
}

.continue-btn {
  width: 100%;
  height: 72px;
  margin-top: 50px;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 36px;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.continue-btn:active {
  margin-top: 51px;
}

.response {
  margin-top: 30px;
  padding: 20px;
  background: #3e3e3e;
  border-radius: 20px;
  word-wrap: break-word;
}

.pincode-input-container {
  display: flex;
  justify-content: space-between;
}

.pincode-input-container .pincode-input-text {
  padding: 0 !important;
  margin: 0 2px !important;
  text-align: center !important;
  border: 1px solid #979797 !important;
  background: #3e3e3e !important;
  width: 18% !important;
  height: 15vw !important;
  max-height: 144px !important;
  color: #fff !important;
  /* text-shadow: 0 0 0 #fff !important; */
  font-size: 6.5rem !important;
  border: none !important;
  border-radius: 20px !important;
  box-sizing: border-box !important;
}

.pincode-input-container .pincode-input-text:focus {
  outline: none !important;
  box-shadow: none !important;
  position: relative !important;
}

.auth-btn {
  display: block;
  max-width: 392px;
  width: 100%;
  height: 4.5rem;
  padding-left: 60px;
  padding-right: 20px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  color: #757575;
  border: 1px solid #fff;
  border-radius: 36px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
}

.auth-btn.google {
  background: #fff url('../images/google-icon.svg') 1.5rem no-repeat;;
  background-size: 2rem;
}

.auth-btn.apple {
  background: #fff url('../images/apple-icon.svg') 1.5rem no-repeat;;
  background-size: 2rem;
}

.email-verification-form {
  margin-top: 30px;
}

.email-verification-form h2 {
  text-align: center;
  font-weight: 400;
}

.email-input, .email-btn {
  width: 100%;
  height: 3.5rem;
  margin: 1rem 0;
  font-size: 1.2rem;
  border: none;
  border-radius: 50px;
  outline: none;
}

.email-input {
  background: #fff;
  padding: 0 2rem;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

.email-btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width: 1200px) {
  .App-note {
    display: none;
  }
  .App-content {
    flex: 100%;
  }
  .App-illustration {
    display: none;
  }
  .pincode-input-container .pincode-input-text { 
    width: 18% !important;
    height: 160px !important;
  }
}

@media screen and (max-width: 650px) {
  html {
    font-size: 13px;
  }
  .App-body {
    width: 95%;
    max-width: 100%;
  }
  .continue-btn {
    height: 40px;
  }
  .pincode-input-container .pincode-input-text { 
    width: 18% !important;
    height: 25vw !important;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 10px;
  }
  .pincode-input-container .pincode-input-text {
    font-size: 6rem !important;
  }
}